import { APIError, AuthError } from "@/services/error_service";
import {
  CategoryAttributeModel,
  CategoryAttributeRequestOptions
} from "@/models/category_attribute";
import axios from "axios";

/**
 * Creates a new Category Attribute Service

 * @example
 * const categoryAttributeService = new CategoryAttributeService();
 * categoryAttributeService.getattribute();
 */

export default class CategoryAttributeService {
  /**
   * Get list of attributes
   * Filtered by parameters
   * @param {string} q Query term for keyword search
   * @returns {APIResponse} - response object
   */
  public async getAttributes({
    id,
    q
  }: CategoryAttributeRequestOptions): Promise<CategoryAttributeModel[]> {
    let attributes: CategoryAttributeModel[] = [];
    let char = "?";
    let counter = 0;
    let url =
      process.env.VUE_APP_API_URL_NODE + "/category/" + id + "/all_filters";

    if (typeof q === "string" && q.length > 0) {
      counter++;
      if (counter > 1) {
        char = "&";
      }
      url += char + "q=" + encodeURIComponent(q);
    }

    try {
      const res = await axios.get(url);
      attributes = res.data?.attributes;
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
    return attributes;
  }
}
